'use client';
import type { MetricsCubeQueryData } from '@carbonfact/shared/src/types/platform/metrics-cube';
import { NetZeroChartType } from 'app/components/Chart/variants/NetZero';
import useEndpoint from 'app/lib/useEndpoint';

import useMetricsCubeQuery from './useMetricsCubeQuery';

export interface NetZeroTrajectoryData {
  rawData: MetricsCubeQueryData[];
  isLoading: boolean;
  chartType: NetZeroChartType;
  formattedDataPoints: { year: number; value: number }[];
  baselineYear: number;
  baselineYearValue: number;
  modelingBaselineYear: number;
  modelingBaselineYearValue: number;
}

export default function useNetZeroTrajectoryData(
  chartType: NetZeroChartType = NetZeroChartType.TotalFootprint,
): NetZeroTrajectoryData {
  const { data: accountMetadata, isLoading: isLoadingMetadata } = useEndpoint(
    '/metadata',
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const { data: cubeData, isLoading: isLoadingCubeData } = useMetricsCubeQuery({
    metric: getMetricForChartType(chartType),
    timeFrame: 'YEAR',
  });

  const isLoading = isLoadingMetadata || isLoadingCubeData;

  if (!accountMetadata || !cubeData || cubeData.length === 0) {
    return {
      rawData: [],
      isLoading,
      chartType,
      formattedDataPoints: [],
      baselineYear: 0,
      baselineYearValue: 0,
      modelingBaselineYear: 0,
      modelingBaselineYearValue: 0,
    };
  }

  // The metrics cube always returns data in descending chronological order
  const firstYear = Number(cubeData.at(-1)?.period);
  const firstYearValue = cubeData.at(-1)?.metricValue;

  // Read baseline year from account settings
  const baselineYear = accountMetadata.baselineYear;
  const baselineYearValue = cubeData.find(
    (d) => d.period === String(baselineYear),
  )?.metricValue;

  let modelingBaselineYear = accountMetadata.modelingBaselineYear;
  let modelingBaselineYearValue = cubeData.find(
    (d) => d.period === String(modelingBaselineYear),
  )?.metricValue;

  const formattedDataPoints = cubeData
    .map((d) => ({
      year: Number(d.period),
      value: d.metricValue,
    }))
    // Remove all data points before baseline year if it is set
    .filter((point) => (baselineYear ? point.year >= baselineYear : true));

  const latestYear = Number(cubeData[0].period);
  const latestYearValue = cubeData[0].metricValue;

  if (!modelingBaselineYear || !modelingBaselineYearValue) {
    const hasMoreThanOneYearOfData = cubeData.length > 1;

    if (hasMoreThanOneYearOfData) {
      // We don't want current year as it's usually incomplete
      const isLatestYearCurrentYear = latestYear === new Date().getFullYear();

      modelingBaselineYear = isLatestYearCurrentYear
        ? // get one year before
          Number(cubeData[1].period)
        : latestYear;

      modelingBaselineYearValue = isLatestYearCurrentYear
        ? // get one year before
          cubeData[1].metricValue
        : latestYearValue;
    } else {
      modelingBaselineYear = latestYear;
      modelingBaselineYearValue = latestYearValue;
    }
  }

  let divider = 1;

  if (chartType === NetZeroChartType.TotalFootprint) {
    divider = 1000;
  }

  return {
    rawData: cubeData,
    isLoading,
    chartType,
    formattedDataPoints: formattedDataPoints.map((d) => ({
      year: d.year,
      value: d.value / divider,
    })),
    baselineYear: baselineYear || firstYear,
    baselineYearValue: (baselineYearValue || firstYearValue || 0) / divider,
    modelingBaselineYear: modelingBaselineYear,
    modelingBaselineYearValue: modelingBaselineYearValue / divider,
  };
}

function getMetricForChartType(chartType: NetZeroChartType): string {
  switch (chartType) {
    case NetZeroChartType.TotalFootprint:
      return 'FOOTPRINT';
    case NetZeroChartType.PerUnitFootprint:
      return 'composite_FOOTPRINT_PER_UNIT';
  }
}
